<template>
  <div>
    <van-popup v-model="show" position="bottom">
      <van-picker
          :title="$t('语言切换')"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import {Picker,Popup } from 'vant';
import {changeLanguage} from "../../../util/jsinterfaces-util";
import {setLanguage} from "../../../util/session-storage-utils";
export default {
  name: "LanguagePicker",
  props: {
    onClose: {
      type: Function,
      require: true,
      default: null,
    },
  },
  components:{
    [Picker.name]:Picker,
    [Popup.name]:Popup,
  },
  data() {
    return {
      columns: ['中文简体', '中文繁體', 'English'],
      values: ['zh', 'zh-tw', 'en'],
      show:true
    };
  },
  methods: {
    onConfirm(value, index) {
      let language = this.values[index]
      setLanguage(language)
      changeLanguage(language)
    },
    onCancel() {
      this.onClose();
    },
  },
}
</script>

<style scoped>

</style>
